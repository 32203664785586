import { Link } from "gatsby";
import React from "react";
import LandingPageBackground from "../assets/landing_page_background.jpeg";
import {
  RealmAppProvider,
  APP_ID,
  OnboardingRedirector,
} from "../components/realm_app";
import OnboardingNavbar from "../components/onboarding_navbar";
import { Footer } from "../components/footer";
import { Button } from "react-bootstrap";
import "../index.css";

const LandingPage = () => {
  return (
    <>
      <div className="page-header">
        <OnboardingNavbar />
      </div>
      <div
        className="full-page-shorter"
        style={{
          backgroundImage: `url(${LandingPageBackground})`,
          backgroundSize: "cover",
          backgroundPosition: "right top",
        }}
      >
        <div className="page-content-side-by-side">
          <div style={{ flexGrow: 9 }} />
          <div style={{ flexGrow: 2, marginTop: "10vh" }}>
            <h1 className="white-text">Welcome to WeChampion!</h1>
            <h6 className="white-text">
              Connect and grow with your colleagues through mutual support.
            </h6>
            <br />
            <Link to="/member/signup/">
              <Button className="light-green-button">Create Account</Button>
            </Link>
            &nbsp;&nbsp;&nbsp;
            <Link to="/member/login/">
              <Button className="light-gray-button">Sign In</Button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ({ location }) => {
  return (
    <RealmAppProvider appId={APP_ID}>
      <OnboardingRedirector location={location}>
        <LandingPage />
      </OnboardingRedirector>
    </RealmAppProvider>
  );
};
