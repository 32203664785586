import * as React from "react";
import { Button } from "react-bootstrap";

export const Footer = () => {
  function openContact() {
    window.open("mailto:contact@mentoramp.com", "_blank");
  }

  return (
    <footer className="footer">
      <div className="footer-content d-flex flex-column">
        <Button className="light-button text-center" onClick={openContact}>
          Contact
        </Button>
        <span className="py-2 text-light settings-footer-text">
          Powered by WeChampion
        </span>
      </div>
    </footer>
  );
};
