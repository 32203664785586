import * as React from "react";
import Navbar from "react-bootstrap/Navbar";
// import logo from "../assets/deepen-logo-black.png";
import logo from "../assets/MentorAmp_Logo.png";

export default ({ link = "/member/dashboard/" }) => {
  return (
    <Navbar>
      <Navbar.Brand href={link}>
        <img
          alt=""
          src={logo}
          width="150"
          className="d-inline-block align-top"
        />
      </Navbar.Brand>
    </Navbar>
  );
};
